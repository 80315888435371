import WooCommerceIcon from '../../assets/woocommerce.png';
import MagentoIcon from '../../assets/magento.png';
import OpencartIcon from '../../assets/opencart.png';
import PrestashopIcon from '../../assets/prestashop.png';

import {
  cubeSVG,
  settingSVG,
  IntegrationSVG,
  settingsPlusSVG,
  cmsSVG,
  pspSvg,
  fastcheckoutSVG,
  autofillSVG,
  designSVG,
  shipSVG,
  notificationSVG,
  marketingSVG,
  infoSVG,
  halfStarSVG,
  trackingSVG,
  classesSVG,
  // chartSVG,
  dashboardSVG,
  shoppingBagSVG,
  discountsSVG,
  securitySVG,
  subscribeSVG,
  webhookSVG,
  importSVG,
  syncSVG,
  RecommendationsSVG,
  userSettingSVG,
  rocketSVG,
  eventSVG,
} from '../allCustomSVGs';
import { allRoutes } from './route';

// Define the SidebarLink type
type SidebarLink = {
  icon: string | JSX.Element;
  iconType: string;
  title: string;
  link: string;
  urlName: string;
  level: number;
  specialURL?: string;
};

// Define the type for all routes keys
type RouteKey = keyof typeof allRoutes;

const linksTopLevel_seller: SidebarLink[] = [
  {
    icon: dashboardSVG,
    iconType: 'svg',
    title: 'Dashboard',
    link: '/dashboard',
    urlName: 'dashboard',
    level: 1,
  },
  {
    icon: cubeSVG,
    iconType: 'svg',
    title: 'Products',
    link: '/products',
    urlName: 'product',
    level: 1,
  },
  {
    icon: shoppingBagSVG,
    iconType: 'svg',
    title: 'Orders',
    link: '/orders',
    urlName: 'order',
    level: 1,
  },
  {
    icon: IntegrationSVG,
    iconType: 'svg',
    title: 'Integration',
    link: '/integrations',
    urlName: 'integrations',
    level: 1,
  },
  {
    icon: trackingSVG,
    iconType: 'svg',
    title: 'AICheckoutTracking',
    link: '/tracking',
    urlName: 'tracking',
    level: 1,
  },
  {
    icon: settingsPlusSVG,
    iconType: 'svg',
    title: 'Settings',
    link: '/settings',
    urlName: 'settings',
    level: 1,
  },
];

const linksAddProduct: SidebarLink[] = [
  {
    icon: cubeSVG,
    iconType: 'svg',
    title: 'AddProduct',
    link: '/products/add',
    urlName: 'add',
    level: 2,
  },
];

const linksIntegrations: SidebarLink[] = [
  {
    icon: cmsSVG,
    iconType: 'svg',
    title: 'CMS',
    link: '/integrations/cms',
    urlName: 'cms',
    level: 2,
  },
  {
    icon: fastcheckoutSVG,
    iconType: 'svg',
    title: 'FastCheckout',
    link: '/integrations/fastcheckout',
    urlName: 'fastcheckout',
    level: 2,
  },
  {
    icon: fastcheckoutSVG,
    iconType: 'svg',
    title: 'SocialCommerce',
    link: '/integrations/socialcommerce',
    urlName: 'socialcommerce',
    level: 2,
  },
  {
    icon: autofillSVG,
    iconType: 'svg',
    title: 'Autofill',
    link: '/integrations/autofill',
    urlName: 'autofill',
    level: 2,
  },
];
export const linksTracking: SidebarLink[] = [
  {
    icon: rocketSVG,
    iconType: 'svg',
    title: 'Integration',
    link: '/tracking/integration',
    urlName: 'integration',
    specialURL: '/tracking/integration',
    level: 2,
  },
  {
    icon: classesSVG,
    iconType: 'svg',
    title: 'CSSTracking',
    link: '/tracking/css',
    urlName: 'css',
    level: 2,
  },
  {
    icon: eventSVG,
    iconType: 'svg',
    title: 'EventTracking',
    link: '/tracking/events',
    urlName: 'events',
    specialURL: '/tracking/events',
    level: 2,
  },

  // {
  //   icon: autofillSVG,
  //   iconType: 'svg',
  //   title: 'Autofill',
  //   link: '/tracking/autofill',
  //   urlName: 'autofill',
  //   level: 2,
  // },
  {
    icon: RecommendationsSVG,
    iconType: 'svg',
    title: 'Recommendations',
    link: '/tracking/trends',
    urlName: 'trends',
    specialURL: '/tracking/trends',
    level: 2,
  },
];

const linksSettings: SidebarLink[] = [
  {
    icon: settingSVG,
    iconType: 'svg',
    title: 'General',
    link: '/settings/general',
    urlName: 'general',
    level: 2,
  },
  {
    icon: designSVG,
    iconType: 'svg',
    title: 'Appearance',
    link: '/settings/appearance',
    urlName: 'appearance',
    level: 2,
  },
  {
    icon: pspSvg,
    iconType: 'svg',
    title: 'Payments',
    link: '/settings/payments',
    urlName: 'payments',
    level: 2,
  },
  {
    icon: shipSVG,
    iconType: 'svg',
    title: 'Shipping',
    link: '/settings/shipping',
    urlName: 'shipping',
    level: 2,
  },
  {
    icon: pspSvg,
    iconType: 'svg',
    title: 'ConvenienceFee',
    link: '/settings/convenienceFee',
    urlName: 'convenienceFee',
    level: 2,
  },
  {
    icon: discountsSVG,
    iconType: 'svg',
    title: 'Discounts',
    link: '/settings/discounts',
    urlName: 'discounts',
    level: 2,
  },
  {
    icon: notificationSVG,
    iconType: 'svg',
    title: 'Notifications',
    link: '/settings/notifications',
    urlName: 'notifications',
    level: 2,
  },
  {
    icon: subscribeSVG,
    iconType: 'svg',
    title: 'Subscription',
    link: '/settings/subscription',
    urlName: 'subscription',
    level: 2,
  },
  {
    icon: webhookSVG,
    iconType: 'svg',
    title: 'Webhooks',
    link: '/settings/webhooks',
    urlName: 'webhooks',
    level: 2,
  },
  {
    icon: marketingSVG,
    iconType: 'svg',
    title: 'Marketing',
    link: '/settings/marketing',
    urlName: 'marketing',
    level: 2,
  },
  {
    icon: securitySVG,
    iconType: 'svg',
    title: 'Security',
    link: '/settings/security',
    urlName: 'security',
    level: 2,
  },
  {
    icon: userSettingSVG,
    iconType: 'svg',
    title: 'Profile',
    link: '/settings/profile',
    urlName: 'profile',
    level: 2,
  },
];

const linksCMS: SidebarLink[] = [
  {
    icon: IntegrationSVG,
    iconType: 'svg',
    title: 'Connection',
    link: '/integrations/cms/connection',
    urlName: 'connection',
    level: 3,
  },
  {
    icon: importSVG,
    iconType: 'svg',
    title: 'ImportCSV',
    link: '/integrations/cms/import',
    urlName: 'import',
    level: 3,
  },
  {
    icon: syncSVG,
    iconType: 'svg',
    title: 'Sync',
    link: '/integrations/cms/sync',
    urlName: 'sync',
    level: 3,
  },
];

// const linksPSP = [
//   {
//     icon: IntegrationSVG,
//     iconType: 'svg',
//     title: 'Connection',
//     link: '/settings/payments/connection',
//     urlName: 'connection',
//     level: 3,
//   },
// ];

const linksFastcheckout: SidebarLink[] = [
  {
    icon: WooCommerceIcon,
    iconType: 'img',
    title: 'WooCommerce',
    link: '/integrations/fastcheckout/woocommerce',
    urlName: 'woocommerce',
    level: 3,
  },
  {
    icon: MagentoIcon,
    iconType: 'img',
    title: 'Magento',
    link: '/integrations/fastcheckout/magento',
    urlName: 'magento',
    level: 3,
  },
  {
    icon: OpencartIcon,
    iconType: 'img',
    title: 'OpenCart',
    link: '/integrations/fastcheckout/opencart',
    urlName: 'opencart',
    level: 3,
  },
  {
    icon: PrestashopIcon,
    iconType: 'img',
    title: 'PrestaShop',
    link: '/integrations/fastcheckout/prestashop',
    urlName: 'prestashop',
    level: 3,
  },
  /* {
    icon: LightspeedIcon,
    iconType: 'img',
    title: 'Lightspeed',
    link: '/integrations/fastcheckout/lightspeed',
    urlName: 'lightspeed',
    level: 3,
  }, */
  {
    icon: halfStarSVG,
    iconType: 'svg',
    title: 'CustomIntegration',
    link: '/integrations/fastcheckout/custom',
    urlName: 'custom',
    level: 3,
  },
];

const linksAutofill: SidebarLink[] = [
  {
    icon: IntegrationSVG,
    iconType: 'svg',
    title: 'Connections',
    link: '/integrations/autofill/connections',
    urlName: 'connections',
    level: 3,
  },
  {
    icon: infoSVG,
    iconType: 'svg',
    title: 'Instructions',
    link: '/integrations/autofill/instructions',
    urlName: 'instructions',
    level: 3,
  },
];

export const sellerAllSidebarLinks: SidebarLink[] = [
  ...linksTopLevel_seller,
  ...linksAddProduct,
  // ...linksWebshop,
  ...linksIntegrations,
  ...linksSettings,
  ...linksCMS,
  ...linksTracking,
  // ...linksPSP,
  ...linksFastcheckout,
  ...linksAutofill,
  // ...linksProducts,
].filter((link) => allRoutes[link.link as RouteKey]);
