import { t } from 'i18next';

export const recommendation_packages = [
  {
    id: 1,
    name: t('Starter'),
    annual_slug: 'starter-annually',
    monthly_slug: 'starter-monthly',
    monthly_price: '€99',
    annual_price: '€90',
    description: t('StarterDescription'),
    features: [
      t('StarterFeature-1'),
      t('StarterFeature-2'),
      t('StarterFeature-3'),
      t('StarterFeature-4'),
    ],
    is_most_popular: false,
  },
  {
    id: 2,
    name: t('Professional'),
    annual_slug: 'professional-annually',
    monthly_slug: 'professional-monthly',
    monthly_price: '250',
    annual_price: '€225',
    description: t('ProfessionalDescription'),
    features: [
      t('ProfessionalFeature-1'),
      t('ProfessionalFeature-2'),
      t('ProfessionalFeature-3'),
      t('ProfessionalFeature-4'),
      t('ProfessionalFeature-5'),
    ],
    is_most_popular: true,
  },
  {
    id: 3,
    name: t('Enterprise'),
    annual_slug: 'company-annually',
    monthly_slug: 'company-monthly',
    monthly_price: '€1000',
    annual_price: '€900',
    description: t('EnterpriseDescription'),
    features: [
      t('EnterpriseFeature-1'),
      t('EnterpriseFeature-2'),
      t('EnterpriseFeature-3'),
      t('EnterpriseFeature-4'),
      t('EnterpriseFeature-5'),
    ],
    is_most_popular: false,
  },
];
