import { APP_ENV } from './central-api';

export const HOME_ROUTE = '/dashboard';
// export const HOME_ROUTE = '/integrations';

// visibility of routes based on environment
const routeVisibility = {
  DEV: {
    // Top Level
    '/dashboard': true,
    '/products': true,
    '/orders': true,
    '/integrations': true,
    '/tracking': true,
    '/settings': true,

    // Products
    '/products/add': true,

    // Integrations
    '/integrations/cms': true,
    '/integrations/fastcheckout': true,
    '/integrations/socialcommerce': false,
    '/integrations/autofill': false,

    // CMS
    '/integrations/cms/connection': true,
    '/integrations/cms/import': false,
    '/integrations/cms/sync': false,

    // Fast Checkout
    '/integrations/fastcheckout/woocommerce': true,
    '/integrations/fastcheckout/magento': true,
    '/integrations/fastcheckout/opencart': true,
    '/integrations/fastcheckout/prestashop': true,
    '/integrations/fastcheckout/lightspeed': false,
    '/integrations/fastcheckout/custom': true,

    // Autofill
    '/integrations/autofill/connections': false,
    '/integrations/autofill/instructions': false,

    // Tracking
    '/tracking/trends': true,
    '/tracking/css': true,
    '/tracking/integration': true,
    '/tracking/autofill': false,
    '/tracking/events': true,

    // Settings
    '/settings/general': true,
    '/settings/appearance': true,
    '/settings/payments': true,
    '/settings/shipping': true,
    '/settings/convenienceFee': true,
    '/settings/discounts': true,
    '/settings/notifications': true,
    '/settings/subscription': false,
    '/settings/webhooks': false,
    '/settings/marketing': true,
    '/settings/security': true,
    '/settings/profile': true,

    // Payments
    '/settings/payments/connection': true,
    '/settings/payments/options': true,
  },
  STAGING: {
    // Top Level
    '/dashboard': true,
    '/products': false,
    '/orders': false,
    '/integrations': false,
    '/tracking': true,
    '/settings': true,

    // Products
    '/products/add': true,

    // Integrations
    '/integrations/cms': true,
    '/integrations/fastcheckout': true,
    '/integrations/socialcommerce': false,
    '/integrations/autofill': false,

    // CMS
    '/integrations/cms/connection': true,
    '/integrations/cms/import': false,
    '/integrations/cms/sync': false,

    // Fast Checkout
    '/integrations/fastcheckout/woocommerce': true,
    '/integrations/fastcheckout/magento': true,
    '/integrations/fastcheckout/opencart': true,
    '/integrations/fastcheckout/prestashop': true,
    '/integrations/fastcheckout/lightspeed': false,
    '/integrations/fastcheckout/custom': true,

    // Autofill
    '/integrations/autofill/connections': false,
    '/integrations/autofill/instructions': false,

    // Tracking
    '/tracking/trends': true,
    '/tracking/css': true,
    '/tracking/integration': true,
    '/tracking/autofill': false,
    '/tracking/events': true,

    // Settings
    '/settings/general': true,
    '/settings/appearance': true,
    '/settings/payments': true,
    '/settings/shipping': true,
    '/settings/convenienceFee': true,
    '/settings/discounts': true,
    '/settings/notifications': true,
    '/settings/subscription': false,
    '/settings/webhooks': false,
    '/settings/marketing': true,
    '/settings/security': true,
    '/settings/profile': true,

    // Payments
    '/settings/payments/connection': true,
    '/settings/payments/options': true,
  },
  LIVE: {
    // Top Level
    '/dashboard': true,
    '/products': false,
    '/orders': false,
    '/integrations': false,
    '/tracking': true,
    '/settings': true,

    // Products
    '/products/add': false,

    // Integrations
    '/integrations/cms': false,
    '/integrations/fastcheckout': false,
    '/integrations/socialcommerce': false,
    '/integrations/autofill': false,

    // CMS
    '/integrations/cms/connection': false,
    '/integrations/cms/import': false,
    '/integrations/cms/sync': false,

    // Fast Checkout
    '/integrations/fastcheckout/woocommerce': false,
    '/integrations/fastcheckout/magento': false,
    '/integrations/fastcheckout/opencart': false,
    '/integrations/fastcheckout/prestashop': false,
    '/integrations/fastcheckout/lightspeed': false,
    '/integrations/fastcheckout/custom': false,

    // Autofill
    '/integrations/autofill/connections': false,
    '/integrations/autofill/instructions': false,

    // Tracking
    '/tracking/trends': true,
    '/tracking/css': true,
    '/tracking/integration': true,
    '/tracking/autofill': false,
    '/tracking/events': true,

    // Settings
    '/settings/general': true,
    '/settings/appearance': false,
    '/settings/payments': false,
    '/settings/shipping': false,
    '/settings/convenienceFee': false,
    '/settings/discounts': false,
    '/settings/notifications': false,
    '/settings/subscription': false,
    '/settings/webhooks': false,
    '/settings/marketing': false,
    '/settings/security': false,
    '/settings/profile': true,

    // Payments
    '/settings/payments/connection': false,
    '/settings/payments/options': false,
  },
};

const allRoutes = routeVisibility[APP_ENV];

export { allRoutes };
