import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import get_graph_data_api_call from '../../../../lib/api/stats/get_graph_data_api_call';
import '../../../../styles/components/charts/pieChartComponent.scss';
import ChartWithConfig from '../../ChartWithConfig';
import isNotEmptyString from '../../../../lib/helper-functions/isNotEmptyString';
import capitalizeFirstLetterOfEachWord from '../../../../lib/helper-functions/capitalizeFirstLetterOfEachWord';
import OverallCTRPerTimeOfDay from './OverallCTRPerTimeOfDay';

type Props = {
  timeFilter: string;
  customPeriodFilterDateRange: {
    start_date: string | undefined;
    end_date: string | undefined;
  };
  eventFilter: string;
};

const MainCTRPerTimeOfDayChart = ({
  timeFilter = 'all',
  customPeriodFilterDateRange,
  eventFilter = 'purchase',
}: Props) => {
  const { t } = useTranslation();
  const stats = useSelector((state: RootState) => state.user?.stats);
  const [params, setParams] = useState<string>(
    `?graph_type=ctr_graph&time_range=${timeFilter}&group_by=time_of_day&event_type=${eventFilter}`
  );
  const [response, setResponse] = useState<any>('initial');
  const [title, setTitle] = useState<string>(t('ClickThroughRateByTimeOfDay'));
  const [comment, setComment] = useState<string>(t('OverallClickThroughRate'));

  /* ---------API CALL----------- */
  const getGraphData = async () => {
    try {
      // Call to get conversion rate stats by location
      const res = await get_graph_data_api_call(
        stats.ai_tracker_api_key,
        stats.ai_tracker_access_token,
        params
      );

      if (res) {
        setResponse({ type: 'overall', data: res });
      }
    } catch (error) {
      console.error('Error fetching conversion rate data:', error);
    }
  };

  useEffect(() => {
    let dateRangeURLParam = '';
    if (timeFilter === 'custom') {
      const { start_date, end_date } = customPeriodFilterDateRange || {};
      if (start_date === undefined || end_date === undefined) {
        return;
      }
      dateRangeURLParam = `&start_date=${start_date}&end_date=${end_date}`;
    }

    let groupByURLParam = `&group_by=time_of_day`;

    let eventURLParam = '';
    if (isNotEmptyString(eventFilter)) {
      eventURLParam = `&event_type=${eventFilter}`;
    }
    setParams(
      `?graph_type=ctr_graph&time_range=${timeFilter}${dateRangeURLParam}${groupByURLParam}${eventURLParam}`
    );
  }, [timeFilter, customPeriodFilterDateRange, eventFilter]);

  useEffect(() => {
    if (
      stats.ai_tracker_api_key !== '' &&
      stats.ai_tracker_access_token !== ''
    ) {
      setResponse('initial');
      getGraphData();
    }
  }, [params, stats.ai_tracker_access_token, stats.ai_tracker_api_key]);

  useEffect(() => {
    if (eventFilter === 'add_to_cart') {
      setTitle(
        capitalizeFirstLetterOfEachWord(
          `${t('ClickThroughRate')} ${t('of')} ${eventFilter} ${t(
            'ByTimeOfDay'
          )}`
        )
      );
      setComment('');
    } else if (eventFilter === 'pay_button') {
      setTitle(
        capitalizeFirstLetterOfEachWord(
          `${t('ClickThroughRate')} ${t('of')} ${eventFilter} ${t(
            'ByTimeOfDay'
          )}`
        )
      );
      setComment('');
    } else if (eventFilter === 'go_to_checkout') {
      setTitle(
        capitalizeFirstLetterOfEachWord(
          `${t('ClickThroughRate')} ${t('of')} ${eventFilter} ${t(
            'ByTimeOfDay'
          )}`
        )
      );
      setComment('');
    } else {
      setTitle(t('ClickThroughRateByTimeOfDay'));
    }
  }, [eventFilter]);

  return (
    <ChartWithConfig
      title={title}
      //   comment={comment}
      isConfigHidden={true}
    >
      <OverallCTRPerTimeOfDay apiResponse={response} />
    </ChartWithConfig>
  );
};

export default MainCTRPerTimeOfDayChart;
