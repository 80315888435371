import { useEffect, useState } from 'react';
import MainSquareButton from '../../../ui/buttons/MainSquareButton';
import { useDispatch, useSelector } from 'react-redux';
import {
  setAiOnboardingNextStep,
  setAiOnboardingSelectedIntegrate,
  setAiOnboardingStepCompleted,
  setAiTrackerAPIKey,
  setIsAiOnboardingIntegrationConnected,
} from '../../../store/reducers/userSlice';
import { useTranslation } from 'react-i18next';
import '../../../styles/pages/quickOnboarding/quickOnboardingIntegrations.scss';
import CustomSVGs from '../../../ui/customSVGs/CustomSVGs';
import { cartSVG, shopifySVG } from '../../../lib/allCustomSVGs';
import post_integration_api_call from '../../../lib/api/integration/post_integration_api_call';
import LoadingSpinner from '../../../ui/loadingSpinner/LoadingSpinner';
import NoData from '../../../ui/noData/NoData';
import { CodeSection } from 'react-code-section-lib';
import {
  regularScriptCode,
  shopifyPixelCode,
} from '../../../lib/constants/integration_script_code';
import { RootState } from '../../../store/store';
import { asyncHandler } from '../../../lib/api/async-handler';
import { RequestInfo } from '../../../interfaces/RequestInfo';
import {
  CHECK_CONNECTION_API,
  HELP_LINE_API,
} from '../../../lib/constants/central-api';
import { applicationWWWXRLEncodedBodyBuilder } from '../../../lib/utils/body-builder';
import update_all_shop_stats_api_call from '../../../lib/api/dashboard/update_all_shop_stats_api_call';
import { setMessage } from '../../../store/reducers/appSlice';
import onboard_integration_zoho_api_call from '../../../lib/api/integration/onboard_integration_zoho_api_call';
import get_checkout_tracking_api_call from '../../../lib/api/integration/get_checkout_tracking_api_call';

interface ApiDataType {
  api_key: string;
  [key: string]: any;
}

type Props = {
  apiKey: string;
  setAPIKey: (apiKey: string) => void;
};

const QuickOnboardingIntegrations = ({ apiKey, setAPIKey }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const companyDetails = useSelector((state: RootState) => state.user.company);
  const shopSlug = useSelector(
    (state: RootState) => state.user.company?.shop_slug
  );
  const accessToken = useSelector(
    (state: RootState) => state.auth.authorization.accessToken
  );
  const isWhiteLabel = useSelector((state: RootState) => state.UI.isWhiteLabel);

  let sellerToken: string;
  if (isWhiteLabel) {
    sellerToken = accessToken?.currentWhiteLabelSellerToken;
  } else {
    sellerToken = accessToken?.sellerToken;
  }
  const selected_integrate = useSelector(
    (state: RootState) =>
      state.user?.ai_onboarding?.selected_integrate || 'standard'
  );
  const is_integration_connected = useSelector(
    (state: RootState) =>
      state.user?.ai_onboarding?.is_integration_connected || false
  );

  const webshop_url = useSelector(
    (state: RootState) => state.user.company?.webshop_url || ''
  );

  const isAppThemeDark = true;
  const [loadingConnection, setLoadingConnection] = useState<
    'pending' | 'initial' | 'null'
  >('initial');

  const [loading, setLoading] = useState<
    'pending' | 'success' | 'error' | 'null'
  >('pending');

  const [connectionErrorCount, setConnectionErrorCount] = useState(0);

  useEffect(() => {
    if (apiKey) {
      setLoading('success');
      return;
    }
    if (shopSlug) {
      getAvailableTrackingData();
    }
  }, [shopSlug]);

  const getAvailableTrackingData = async () => {
    setLoading('pending');
    try {
      const res: ApiDataType[] = await get_checkout_tracking_api_call();

      if (!res || res.length === 0) {
        const sendingData = {
          name: 'checkout-tracking-class-names',
          type: 'tracker',
          configuration: { key: 'value' },
        };
        const postRes = await post_integration_api_call(
          sendingData,
          undefined,
          {
            is_autofill_enabled: 'false',
          },
          {
            is_tracker_active: 'true',
          }
        );
        if (postRes && postRes.api_key) {
          setAPIKey(postRes?.api_key);
          dispatch(setAiTrackerAPIKey(postRes?.api_key));
        } /* else {
            setLoading('null');
          } */
      } else {
        setAPIKey(res[res.length - 1]?.api_key || '');
        dispatch(setAiTrackerAPIKey(res[res.length - 1]?.api_key || ''));
      }
      setLoading('success');
    } catch (error) {
      console.error('Error fetching tracking data:', error);
      setLoading('error');
    }
  };

  const helpLineHandler = async (unableToVerifyConnection?: boolean) => {
    try {
      const sendingData = JSON.stringify({
        type: 'tracking',
        data: {
          connection: unableToVerifyConnection ? 'error' : undefined,
          cms_url: companyDetails?.webshop_url,
          shop_slug: companyDetails?.shop_slug,
        },
      });

      const requestInfo: RequestInfo = {
        url: HELP_LINE_API,
        method: 'POST',
        body: sendingData,
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/json',
          Authorization: accessToken.type + ' ' + sellerToken,
        },
      };

      const actionType = 'HELP_LINE_API_POST';
      const customMsg = t('EmailSentSuccessfully');

      const res: any = await asyncHandler(
        requestInfo,
        actionType,
        customMsg,
        false,
        false,
        true
      );

      const response = res.data;
      if (res.actionType === actionType && response.success) {
        const sendOnboardingStatus = await update_all_shop_stats_api_call(
          3,
          false
        );
        if (!sendOnboardingStatus) {
          throw new Error('Onboarding status submission failed');
        }
        setLoadingConnection('initial');
        setTimeout(() => {
          dispatch(
            setAiOnboardingStepCompleted({ stepNumber: 2, isCompleted: true })
          );
          dispatch(setAiOnboardingNextStep(3));
        }, 1000);
      }
    } catch (exception) {
      setLoadingConnection('initial');
      console.log('Error during authentication request');
    }
  };

  const checkConnection = async () => {
    if (!apiKey || !webshop_url || webshop_url === '') return;

    setLoadingConnection('pending');
    try {
      const sendingData = {
        tracking_id: apiKey,
        site_url: webshop_url,
      };

      const requestInfo: RequestInfo = {
        url: CHECK_CONNECTION_API,
        method: 'POST',
        body: applicationWWWXRLEncodedBodyBuilder(sendingData),
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/x-www-form-urlencoded',
        },
      };

      const actionType = 'CHECK_CONNECTION_API';
      const customMsg = 'Connection successful!';

      const res: any = await asyncHandler(
        requestInfo,
        actionType,
        customMsg,
        false,
        false,
        true
      );

      const response = res.data;

      if (
        res.actionType === actionType &&
        response.success === true &&
        response?.data?.script_detected === true
      ) {
        dispatch(setIsAiOnboardingIntegrationConnected(true));
        //call help line
        helpLineHandler();
        dispatch(
          setMessage({
            message: customMsg,
            messageType: 'success',
          })
        );
      } else {
        dispatch(
          setMessage({
            message: t('No tracking script found'),
            messageType: 'warning',
          })
        );
        setLoadingConnection('null');
        setConnectionErrorCount(connectionErrorCount + 1);
        dispatch(setIsAiOnboardingIntegrationConnected(false));
      }
    } catch (error) {
      console.error('Error fetching tracking data:', error);
      setLoadingConnection('null');
    }
  };

  const onboarding_send_instructions_submit = async () => {
    const res = await onboard_integration_zoho_api_call(
      'onboarding_send_instructions_button'
    );
    if (res) {
      setLoadingConnection('initial');

      dispatch(
        setAiOnboardingStepCompleted({ stepNumber: 2, isCompleted: true })
      );
      dispatch(setAiOnboardingNextStep(3));
    }
  };
  const onboarding_enterprise_solution_submit = async () => {
    const res = await onboard_integration_zoho_api_call(
      'onboarding_enterprise_solution_link'
    );
    if (res) {
      setLoadingConnection('initial');

      dispatch(
        setAiOnboardingStepCompleted({ stepNumber: 2, isCompleted: true })
      );
      dispatch(setAiOnboardingNextStep(3));
    }
  };

  const renderCode = () => {
    if (loading === 'pending') return <LoadingSpinner />;
    if (loading === 'error')
      return (
        <NoData
          title={t('ErrorFetchingData')}
          subTitle={t('SomethingWentWrong-msg')}
        />
      );
    if (loading === 'null') return null;
    if (loading === 'success') {
      if (selected_integrate === 'standard' && apiKey) {
        return (
          <CodeSection
            showLineNumbers
            theme={isAppThemeDark ? 'dark' : 'light'}
            lang={'html'}
          >
            {regularScriptCode(apiKey)}
          </CodeSection>
        );
      }
      if (selected_integrate === 'shopify' && apiKey) {
        return (
          <CodeSection
            showLineNumbers
            theme={isAppThemeDark ? 'dark' : 'light'}
            lang={'js'}
          >
            {shopifyPixelCode(apiKey)}
          </CodeSection>
        );
      }
    }
  };

  const renderCodeInstructions = () => {
    if (selected_integrate === 'standard') {
      return (
        <>
          <p>{t('CheckoutTrackingIntegrationInstructions1')}</p>
          <ul>
            <li>
              {t('CheckoutTrackingIntegrationInstructions2')}{' '}
              <strong>{`<head>`}</strong>
            </li>
          </ul>
        </>
      );
    } else {
      return (
        <>
          <p>{t('CheckoutTrackingIntegrationInstructions1-Shopify')}</p>
          <ul>
            <li>{t('CheckoutTrackingIntegrationInstructions2-Shopify')}</li>
            <li>{t('CheckoutTrackingIntegrationInstructions3-Shopify')}</li>
            <li>{t('CheckoutTrackingIntegrationInstructions4-Shopify')}</li>
            <li>{t('CheckoutTrackingIntegrationInstructions5-Shopify')}</li>
            <li>{t('CheckoutTrackingIntegrationInstructions6-Shopify')}</li>
          </ul>
        </>
      );
    }
  };

  return (
    <div className='quickOnboardingIntegrations'>
      {/* title section */}
      <div className='quickOnboardingIntegrations__title'>
        <div className='quickOnboardingIntegrations__title--left'>
          <span>{t('Integrate')}</span>
        </div>
        <div className='quickOnboardingIntegrations__title--right'>
          <div
            data-is-connected={is_integration_connected.toString()}
            className='quickOnboardingIntegrations__title--right-connection'
          >
            <p>
              {is_integration_connected ? t('Connected') : t('Disconnected')}
            </p>
            <span></span>
          </div>
        </div>
      </div>

      {/* cards section */}
      <div className='quickOnboardingIntegrations__cards'>
        <div
          onClick={() => dispatch(setAiOnboardingSelectedIntegrate('standard'))}
          data-is-card-selected={
            selected_integrate === 'standard' ? 'true' : 'false'
          }
          className='quickOnboardingIntegrations__cards--card'
        >
          <div className='quickOnboardingIntegrations__cards--card-icon'>
            <CustomSVGs svg={cartSVG} />
          </div>
          <div className='quickOnboardingIntegrations__cards--card-text'>
            <p>{t('StandardScriptIntegration')}</p>
            <span>{t('StandardScriptIntegration-info')}</span>
          </div>
        </div>
        <div
          onClick={() => dispatch(setAiOnboardingSelectedIntegrate('shopify'))}
          data-is-card-selected={
            selected_integrate === 'shopify' ? 'true' : 'false'
          }
          className='quickOnboardingIntegrations__cards--card'
        >
          <div className='quickOnboardingIntegrations__cards--card-icon'>
            <CustomSVGs svg={shopifySVG} />
          </div>
          <div className='quickOnboardingIntegrations__cards--card-text'>
            <p>{t('ShopifyWebPixelIntegration')}</p>
            <span>{t('ShopifyWebPixelIntegration-info')}</span>
          </div>
        </div>
      </div>

      <div className='quickOnboardingIntegrations__code'>
        <div className='quickOnboardingIntegrations__code--titles'>
          {renderCodeInstructions()}
        </div>
        <div className='quickOnboardingIntegrations__code--code'>
          {renderCode()}
        </div>
      </div>

      <div className='quickOnboardingIntegrations__button'>
        <MainSquareButton
          loading={
            loadingConnection === 'pending' || loading === 'pending'
              ? true
              : false
          }
          onClick={(e) => {
            e.preventDefault();
            checkConnection();
          }}
          type={'button'}
          value={
            loadingConnection === 'initial'
              ? t('CheckConnection')
              : t('TryConnectingAgain')
          }
          normal={true}
        />
      </div>
      {connectionErrorCount > 1 && (
        <div className='quickOnboardingIntegrations__link'>
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => {
              //call help line with unableToVerifyConnection flag
              helpLineHandler(true);
            }}
          >
            {t('UnableToVerifyConnection')}
          </div>
        </div>
      )}
      {loading !== 'pending' && (
        <>
          <div className='quickOnboardingIntegrations__button--light'>
            <MainSquareButton
              onClick={(e) => {
                e.preventDefault();
                onboarding_send_instructions_submit();
              }}
              type={'button'}
              value={t('SendMeTheseInstructions')}
              normal={true}
            />
          </div>

          <div className='quickOnboardingIntegrations__link'>
            <div>{t('LookingForEnterpriseSolutions')}</div>
            <span
              onClick={() => {
                onboarding_enterprise_solution_submit();
              }}
            >
              {t('ClickHere')}
            </span>
          </div>
        </>
      )}
    </div>
  );
};

export default QuickOnboardingIntegrations;
