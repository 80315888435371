import React, { useState } from 'react';
import CustomSVGs from '../../../../ui/customSVGs/CustomSVGs';
import { tickSVG } from '../../../../lib/allCustomSVGs';
import MainSquareButton from '../../../../ui/buttons/MainSquareButton';
import { useTranslation } from 'react-i18next';
import { AnimatePresence, motion } from 'framer-motion';
import make_package_subscriptions_api_call from '../../../../lib/api/ai-tracker/subscriptions/make_package_subscriptions_api_call';
import { submitLogToBugsnag } from '../../../../lib/api/log';
import { HELP_LINE_API } from '../../../../lib/constants/central-api';
import { RequestInfo } from '../../../../interfaces/RequestInfo';
import { asyncHandler } from '../../../../lib/api/async-handler';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';

type Props = {
  isMonthly: boolean;
  setIsPaymentPopupOpen: (value: boolean) => void;
  setIsSupportPopupOpen: (value: boolean) => void;
  packageDetails: {
    id: number;
    name: string;
    annual_slug: string;
    monthly_slug: string;
    monthly_price: string;
    annual_price: string;
    description: string;
    features: string[];
    is_most_popular: boolean;
  };
};

const RecommendationPackageItem = ({
  packageDetails,
  isMonthly,
  setIsPaymentPopupOpen,
  setIsSupportPopupOpen,
}: Props) => {
  const { t } = useTranslation();
  const shopSlug = useSelector(
    (state: RootState) => state.user?.company.shop_slug
  );
  const accessToken = useSelector(
    (state: RootState) => state.auth.authorization.accessToken
  );
  const isWhiteLabel = useSelector((state: RootState) => state.UI.isWhiteLabel);
  let sellerToken: string;
  if (isWhiteLabel) {
    sellerToken = accessToken?.currentWhiteLabelSellerToken;
  } else {
    sellerToken = accessToken?.sellerToken;
  }

  const [loading, setLoading] = useState<'pending' | 'initial'>('initial');

  const onMakeSubscription = async () => {
    setLoading('pending');
    try {
      const res = await make_package_subscriptions_api_call(
        isMonthly ? packageDetails.monthly_slug : packageDetails.annual_slug
      );

      if (res) {
        setLoading('initial');
        setIsPaymentPopupOpen(false);
        if (
          res?.payment_details?.payment_details?._links?.checkout?.href &&
          typeof res?.payment_details?.payment_details?._links?.checkout
            ?.href === 'string'
        ) {
          window.location.href =
            res?.payment_details?.payment_details?._links?.checkout?.href;
        }
      } else {
        setLoading('initial');
        setIsPaymentPopupOpen(false);
      }
    } catch (error) {
      console.error('Error making subscriptions:', error);
      setLoading('initial');
      setIsPaymentPopupOpen(false);
    }
  };

  const onSupportSubmitHandler = async () => {
    setLoading('pending');

    try {
      const sendingData = JSON.stringify({
        type: 'subscription',
        data: {
          // cms_url: shopURL,
          shop_slug: shopSlug,
        },
      });

      const requestInfo: RequestInfo = {
        url: HELP_LINE_API,
        method: 'POST',
        body: sendingData,
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/json',
          Authorization: accessToken.type + ' ' + sellerToken,
        },
      };

      const actionType = 'HELP_LINE_API_POST';
      const customMsg = t('EmailSentSuccessfully');

      const res: any = await asyncHandler(requestInfo, actionType, customMsg);

      const response = res.data;
      if (res.actionType === actionType && response.success) {
        setIsSupportPopupOpen(true);
        setLoading('initial');
      } else {
        setLoading('initial');
      }
    } catch (exception) {
      setLoading('initial');
      console.log('Error during authentication request');
      submitLogToBugsnag('error', `${exception}`);
    }
  };

  const onSubmitHandler = async () => {
    if (
      packageDetails.annual_slug === 'company-annually' ||
      packageDetails.annual_slug === 'company-monthly'
    ) {
      onSupportSubmitHandler();
    } else {
      onMakeSubscription();
    }
  };

  return (
    <div
      data-is-most-popular={packageDetails.is_most_popular.toString()}
      className='recommendationPackageItem'
    >
      {packageDetails.is_most_popular && (
        <div className='recommendationPackageItem__badge'>
          {t('Most popular')}
        </div>
      )}

      <div className='recommendationPackageItem-content'>
        <h2 className='recommendationPackageItem__title'>
          {packageDetails.name}
        </h2>

        <div className='recommendationPackageItem__prices'>
          <AnimatePresence exitBeforeEnter>
            {isMonthly && (
              <motion.div
                key={isMonthly.toString()}
                initial={{ opacity: 0.1 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0.1 }}
                transition={{ duration: 0.3 }}
                className='recommendationPackageItem__prices--monthly'
              >
                <h2>{packageDetails.monthly_price}</h2>
                <p>{t('/month')}</p>
              </motion.div>
            )}
            {!isMonthly && (
              <motion.div
                key={isMonthly.toString()}
                initial={{ opacity: 0.1 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0.1 }}
                transition={{ duration: 0.3 }}
                className='recommendationPackageItem__prices--annual'
              >
                <h3>{packageDetails.monthly_price}</h3>
                <h2>{packageDetails.annual_price}</h2>
                <p>{t('/month')}</p>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
        <p className='recommendationPackageItem__description'>
          {packageDetails.description}
        </p>
        <div className='recommendationPackageItem__features'>
          {packageDetails.features.map((feature, index) => (
            <div
              key={index}
              className='recommendationPackageItem__features--item'
            >
              <div className='recommendationPackageItem__features--item-icon'>
                <CustomSVGs svg={tickSVG} />
              </div>
              <div className='recommendationPackageItem__features--item-text'>
                {feature}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className='recommendationPackageItem__button'>
        <MainSquareButton
          onClick={(e) => {
            e.preventDefault();
            onSubmitHandler();
          }}
          type={'button'}
          value={loading === 'pending' ? t('Loading') : t('GetStarted')}
          normal={true}
        />
      </div>
    </div>
  );
};

export default RecommendationPackageItem;
