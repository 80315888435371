import { useTranslation } from 'react-i18next';
import {
  aiCheckoutSVG,
  autofillSVG,
  classesSVG,
  /*   cmsSettingsSVG,
  connectSVG,
  reloadArrowsSVG, */
  trackingSVG,
} from '../../lib/allCustomSVGs';
import '../../styles/pages/dashboard/dashboardTab.scss';
import DashboardTabItem from './DashboardTabItem';
import { useNavigate } from 'react-router-dom';
/* import { useState } from 'react';
import start_product_sync_api_call from '../../lib/api/sync/start-product-sync-api-call'; */
import ProgressBar from '../../ui/progressBar/ProgressBar';
import { useEffect, useState } from 'react';

type Props = {
  stats: any;
  isTimesUp: boolean;
};

const DashboardTab = ({ stats, isTimesUp }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [progressBarCurrentStep, setProgressBarCurrentStep] =
    useState<number>(0);
  /* const [productSyncStatus, setProductSyncStatus] = useState<
    'pending' | 'success' | 'error' | 'null'
  >('null'); */

  /*  const productSyncHandler = async () => {
    setProductSyncStatus('pending');
    try {
      const res = await start_product_sync_api_call();
      if (res) {
        setProductSyncStatus('success');
        return;
      } else {
        setProductSyncStatus('error');
      }
    } catch (error) {
      console.error('Error fetching tracking data:', error);
      setProductSyncStatus('error');
    }
  }; */

  /* 
  {
    "profile_completion": null,
    "onboarding_stats": {
        "product_sync": false,
        "cms_onboarding": true,
        "psp_onboarding": true
    }
}
  */

  useEffect(() => {
    let progress = 0;

    if (stats?.additional_stats?.is_tracker_classes_configured) {
      progress++;
    }
    if (stats?.additional_stats?.tracker_configured_timestamp) {
      progress++;
    }
    if (isTimesUp) {
      progress++;
    }
    if (stats?.additional_stats?.is_autofill_enabled) {
      progress++;
    }

    setProgressBarCurrentStep(progress);
  }, [isTimesUp, stats]);

  const renderRecommendationSubtitle = () => {
    if (
      !stats?.additional_stats?.tracker_configured_timestamp &&
      stats?.additional_stats?.is_tracker_classes_configured
    ) {
      return t('AwaitingDataFromWebsite');
    } else if (
      stats?.additional_stats?.tracker_configured_timestamp &&
      stats?.additional_stats?.is_tracker_classes_configured
    ) {
      return t('StillCollectingData');
    } else {
      return '';
    }
  };

  const renderDashboardTab = () => {
    if (
      stats?.additional_stats?.is_tracker_classes_configured &&
      stats?.additional_stats?.tracker_configured_timestamp &&
      stats?.additional_stats?.is_autofill_enabled &&
      isTimesUp
    ) {
      return null;
    } else {
      return (
        <div className='dashboardTab--container'>
          <div className='dashboardTab--titles'>
            <h2>{t('LetsGetStarted')}</h2>
            <h3>{t('DashboardEasySteps')}</h3>
            <div className='dashboardTab--titles-steps'>
              <span>{t('DashboardSteps')}</span>
              <span>{`${progressBarCurrentStep} of 4 ${t(
                'StepsCompleted'
              )}`}</span>
            </div>
            <ProgressBar steps={4} currentLevel={progressBarCurrentStep} />
          </div>
          <div className='dashboardTab'>
            {/*  {!stats?.onboarding_stats?.product_sync && (
      <DashboardTabItem
        title={
          productSyncStatus === 'success'
            ? t('DashboardSyncProduct-success')
            : t('DashboardSyncProduct')
        }
        icon={reloadArrowsSVG}
        onClickHandler={() => {
          if (productSyncStatus === 'success') {
            // navigate('/products');
          } else {
            productSyncHandler();
          }
        }}
        loadingStatus={productSyncStatus}
      />
    )}

    {!stats?.onboarding_stats?.psp_onboarding && (
      <DashboardTabItem
        title={t('DashboardConnectPSP')}
        icon={connectSVG}
        onClickHandler={() => {
          navigate('/settings/payments');
        }}
      />
    )}

    {!stats?.onboarding_stats?.cms_onboarding && (
      <DashboardTabItem
        title={t('DashboardConnectCMS')}
        icon={cmsSettingsSVG}
        isFillSVG={true}
        onClickHandler={() => {
          navigate('/integrations/cms');
        }}
      />
    )}
    <DashboardTabItem
      title={t('DashboardIntegrateAICheckout')}
      icon={aiCheckoutSVG}
      onClickHandler={() => {
        navigate('/integrations/fastcheckout');
      }}
    /> */}

            <DashboardTabItem
              isCompleted={
                stats?.additional_stats?.is_tracker_classes_configured
                  ? true
                  : false
              }
              title={t('SetUpYourCSSClasses')}
              isFillSVG={true}
              icon={classesSVG}
              onClickHandler={() => {
                navigate('/tracking/css');
              }}
            />

            <DashboardTabItem
              isCompleted={
                stats?.additional_stats?.tracker_configured_timestamp
                  ? true
                  : false
              }
              title={t('SetUpTracking')}
              icon={trackingSVG}
              isFillSVG={true}
              onClickHandler={() => {
                navigate('/tracking/integration');
              }}
            />

            <DashboardTabItem
              isCompleted={
                stats?.additional_stats?.is_autofill_enabled ? true : false
              }
              title={t('ActivateAutofill')}
              icon={autofillSVG}
              isFillSVG={true}
              onClickHandler={() => {
                navigate('/tracking/autofill');
              }}
            />

            <DashboardTabItem
              isCompleted={isTimesUp}
              title={t('Recommendations')}
              subTitle={renderRecommendationSubtitle()}
              icon={aiCheckoutSVG}
              dateTimeISOString={
                stats?.additional_stats?.tracker_configured_timestamp
              }
              onClickHandler={() => {
                navigate('/tracking/trends');
              }}
              isTimesUp={isTimesUp}
            />
          </div>
        </div>
      );
    }
  };

  return renderDashboardTab();
};

export default DashboardTab;
