import { useTranslation } from 'react-i18next';
import '../../../../styles/pages/checkoutTracking/recommendationPackages.scss';
import { useState } from 'react';
import RecommendationPackageItem from './RecommendationPackageItem';
import { recommendation_packages } from '../../../../lib/constants/recommendation_packages';
import CustomSVGs from '../../../../ui/customSVGs/CustomSVGs';
import { tickWithCircleSVG } from '../../../../lib/allCustomSVGs';

type Props = {
  setIsPaymentPopupOpen: (value: boolean) => void;
};

const RecommendationPackages = ({ setIsPaymentPopupOpen }: Props) => {
  const { t } = useTranslation();
  const [isMonthly, setIsMonthly] = useState<boolean>(true);
  const [isSupportPopupOpen, setIsSupportPopupOpen] = useState<boolean>(false);

  return (
    <>
      {isSupportPopupOpen ? (
        <div className='recommendationPackages--support'>
          <h3>{t('EmailSentSuccessfully')}</h3>
          <CustomSVGs svg={tickWithCircleSVG} />
        </div>
      ) : (
        <div className='recommendationPackages'>
          <div className='recommendationPackages__titles'>
            <h2>{t('Plans&Prices')}</h2>
            <p>{t('ChoosePlan-subtext')}</p>
          </div>
          <div className='recommendationPackages__selection'>
            <div className='recommendationPackages__selection--text'>
              {t('PayMonthly')}
            </div>
            <div className='recommendationPackages__selection--toggle'>
              <input
                className='recommendationPackages__selection--toggle-input'
                type='checkbox'
                id='mode-toggle'
                checked={!isMonthly}
                onChange={() => setIsMonthly(!isMonthly)}
              />
              <label
                className='recommendationPackages__selection--toggle-label'
                htmlFor='mode-toggle'
              ></label>
            </div>

            <div className='recommendationPackages__selection--text'>
              {t('PayAnnually')}
            </div>
            <div className='recommendationPackages__selection--info'>
              {`10%  ${t('Cheaper')}`}
            </div>
          </div>
          <div className='recommendationPackages__items'>
            {recommendation_packages.map((packageDetails) => (
              <RecommendationPackageItem
                key={packageDetails.id}
                isMonthly={isMonthly}
                packageDetails={packageDetails}
                setIsPaymentPopupOpen={setIsPaymentPopupOpen}
                setIsSupportPopupOpen={setIsSupportPopupOpen}
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default RecommendationPackages;
