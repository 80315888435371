import { asyncHandler } from '../async-handler';
import { RequestInfo } from '../../../interfaces/RequestInfo';
import { GRAPHS_API } from '../../constants/central-api';

export default async function get_graph_data_api_call(
  integrationKey: string,
  accessToken: string,
  params: string
) {
  const requestInfo: RequestInfo = {
    url: `${GRAPHS_API(integrationKey) + params}`,
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: 'Bearer' + ' ' + accessToken,
    },
  };

  const actionType = 'GET_CONVERSION_RATE_STATS';
  const customMsg = 'Available conversion rate stats fetched successfully';

  const res: any = await asyncHandler(
    requestInfo,
    actionType,
    customMsg,
    false,
    true,
    false,
    true
  );

  const response = res?.data;
  if (!response.success) {
    return false;
  }
  if (res.actionType === actionType && response.success === true) {
    if (response.data === null) {
      return null;
    } else {
      return response?.data;
    }
  }
}
